// src/App.js
import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Header from './components/header/header';
import Report from './Pages/Report';
import DirectoryList from './Pages/DirectoryList';

const App = () => {
    const [auth, setAuth] = useState(localStorage.getItem('token') ? true : false);  // Auth state to manage authentication

  /*  useEffect(() => {
        // Check if there's a token in localStorage when the app loads
        const token = localStorage.getItem('token');
        if (token) {
            // You could make an API call here to validate the token
            setAuth(true);
        } else{
            setAuth(false)
        }
    }, []);*/

    return (
        <div>
            {auth && <Header></Header>}
        <Router>
            <Routes>
                <Route path="/report" element={auth ? <Report/> : <Navigate to="/login" /> }></Route>
                <Route path="/login" element={auth ? <Navigate to="/dashboard" /> : <Login setAuth={setAuth} />} />
                <Route path="/dashboard" element={auth ? <Dashboard /> : <Navigate to="/login" />} />
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/directorylist" element={auth ? <DirectoryList /> : <Navigate to="/login" />} />
            </Routes>
        </Router>
        </div>
    );
};

export default App;
