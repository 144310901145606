import React, { useState, useEffect,useMemo } from "react";
import Table from "../components/Table/Table";

const DirectoryList = () => {
  const [currentPath, setCurrentPath] = useState("/"); // Root directory
  const [contents, setContents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(contents.length / 10));

  const itemsPerPage = 10;
  useEffect(() => {
    fetch(`/api/directories/?path=${currentPath}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setContents(data)
      
      })
      .catch((err) => console.error("Error fetching contents:", err));
  }, [currentPath]);

  useEffect(() => {
    setTotalPages(Math.ceil(contents.length / itemsPerPage));
  }, [contents]);

   const currentItems = useMemo(() => {
      return contents.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    }, [contents, currentPage]);
  
  const openFolder = (folderName) => {
    setCurrentPath((prevPath) => `${prevPath}${folderName}/`);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goBack = () => {
    if (currentPath !== "/") {
      const parentPath = currentPath.split("/").slice(0, -2).join("/") + "/";
      setCurrentPath(parentPath);
    }
  };

  const handleCreateFolder = () => {
    fetch("/api/directories/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
      body: JSON.stringify({ path: currentPath, name: newFolderName }),
    })
      .then((res) => res.json())
      .then((data) => setContents([...contents, data]))
      .catch((err) => console.error("Error creating folder:", err));
    setNewFolderName("");
    document.getElementById("addDirectoryModal").style.display = "none";
  };

  const handleFileUpload = () => {
    if (!file) return;
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("path", currentPath);

    fetch(`/api/upload/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => setContents([...contents, data]))
      .catch((err) => console.error("Error uploading file:", err));
    setFile(null);
    //setIsModalOpen(false);
    document.getElementById("addFileModal").style.display = "none";
  };

  function closeModal(modal){
    document.getElementById(modal).style.display = "none";
  }

  useEffect(()=>{
    if (file){
      const fileChosen = document.getElementById('file-chosen');
      console.log(file)
      fileChosen.textContent =file.name
    }
    

  },[file])


  function downloadFile(file,fileName) {
 
    fetch(`/api/download/${file}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    })
    .then(response => {
        if (!response.ok) throw new Error("Failed to download file");
        return response.blob();
    })
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;  // Extract file name
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    })
    .catch(error => console.error("Download error:", error));
}

function deleteFile(fileName) {
  fetch("/api/delete-file/", {
      method: "DELETE",
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ path: currentPath,file_name : fileName }),
  })
  .then(response => response.json())
  .then(data => {
     setContents(contents.filter(content => content.name !== fileName)); 
  })
  .catch(error => console.error("Delete error:", error));
}

function deleteDirectory(dirName,e) {
  e.preventDefault();
  e.stopPropagation();
  fetch("/api/delete-directory/", {
      method: "DELETE",
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ name: dirName,path:currentPath }),
  })
  .then(response => response.json())
  .then(data => {
      console.log("Directory deleted:", data);
      setContents(contents.filter(content => content.name !== dirName)); 
  })
  .catch(error => console.error("Delete error:", error));
}


  return (
    <div className="container">

      <div className="row" style={{justifyContent:"flex-start"}}>
        <button className="btn addDirectory" onClick={() => (document.getElementById("addDirectoryModal").style.display = "block")}></button>
        <button className="btn addFile" onClick={() => (document.getElementById("addFileModal").style.display = "block")}></button>
      </div>
      <div className="table document-table">
        <div className="header row">
          <div className="header-item">Megnevezés</div>
          <div className="header-item">Műveletek</div>
        </div>
        <div className="item-list">
        {currentPath !== "/" && <div className="item row" onClick={goBack}>
          <div className="item-field title-field">📁...</div>
          <div className="item-field"></div>
           </div>}
          {currentItems.map((content) => (
            <div className="item row" key={(content.isDirectory? "d" : "f")+content.id}>
              <div className="item-field title-field" onClick={() => (content.isDirectory ? openFolder(content.name) : downloadFile(content.id,content.name))}> {content.isDirectory ? "📁" : "📄"} {content.name}</div>
              <div className="item-field">
                <button className="deleteBtn" onClick={(e) => (content.isDirectory ? deleteDirectory(content.name,e) : deleteFile(content.name))} ></button>
              </div>
            </div>
          ))}
        </div>
      </div>
     

      <div className="modal" id="addDirectoryModal">
        <div className="modal-content">
          <h2>Mappa létrehozása</h2>
          <input type="text" className="input-control" placeholder="Mappa neve" value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} />
          <button className="action-btn" onClick={handleCreateFolder}>Létrehozás</button>
          <button className="cancel-btn" onClick={()=>closeModal("addDirectoryModal")}>Mégsem</button>
        </div>
      </div>

      <div className="modal" id="addFileModal">
        <div className="modal-content">
          <h2>Fájl feltöltés</h2>
          <p>
              <input type="file" id="file" onChange={(e) => setFile(e.target.files[0])} hidden />
              <label htmlFor="file" className="btn btn-primary">Fájl kiválasztása</label>
              <span id="file-chosen">Nincs fájl kiválasztva</span>
          </p>
          <button className="action-btn" onClick={handleFileUpload}>Feltöltés</button>
          <button className="cancel-btn" onClick={()=>closeModal("addFileModal")}>Mégsem</button>
        </div>
      </div>

      {contents.length>0 &&
      <div className="pagination">
        <button onClick={() => handlePageClick(currentPage - 1)} disabled={currentPage === 1}>
          {"<<"}
        </button>

        {[...Array(totalPages)].map((_, index) => (
          <button key={index} onClick={() => handlePageClick(index + 1)} className={index + 1 === currentPage ? "active" : ""}>
            {index + 1}
          </button>
        ))}

        <button onClick={() => handlePageClick(currentPage + 1)} disabled={currentPage === totalPages}>
          >>
        </button>
      </div>
}

    </div>
  );
};

export default DirectoryList;
