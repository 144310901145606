import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await fetch("api/user/", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            credentials: "include",
          });
          const data = await response.json();
          if (data.first_name) {
            setUser(data);
            localStorage.setItem("user", data.id);
          } else {
            // Handle invalid token or unauthorized access
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.href = "/";
          }
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.href = "/";
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []); // Only runs once on mount

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    window.location.href = "/";
  };

  return (
    <UserContext.Provider value={{ user, logOut, loading,setUser }}>
      {children}
    </UserContext.Provider>
  );
};
