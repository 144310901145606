const Modal = ({ id,action,cancel,title,content }) => {
  return (
    <div id={id} class="modal">
      <div class="modal-content">
        <h2>{title}</h2>
        <p>{content}</p>
        <button class="action-btn" onClick={action}>Igen</button>
        <button class="cancel-btn" onClick={cancel}>Mégsem</button>
      </div>
    </div>
  );
};

export default Modal;
