import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";

const Header = () => {
  const { user, logOut } = useContext(UserContext);

  const path  = window.location.pathname.split("/")[1];
  return (
    <>
      {user ? (
        <div className="header-container">
          <nav>
            <ul>
              <li>
                <a href="/dashboard" className={path === "dashboard" ? "active reports" : "reports"} title="dashboard button icons" id="dashboard">Jegyzőkönyvek</a>
              </li>
             
              <li>
                <a href="/directorylist" className={path === "directorylist" ? "active documents" : "documents"} title="directory button icons" id="directory">Dokumentumok</a>
              </li>
            </ul>
          </nav>
          <div className="header-button-container">
            <a href="/dashboard" title="home button icons" id="home"></a>
            <span>Üdv, {user.first_name}</span>
            <button onClick={logOut} id="logout"> </button>
          </div>
          
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
