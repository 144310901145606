// src/components/Login.js

import React, { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const Login = ({ setAuth }) => {
    const { user, setUser } = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };
    
    const csrftoken = getCookie('csrftoken');

    
    const handleSubmit = (e) => {
        e.preventDefault();

         fetch('api/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                
            },
            body: JSON.stringify({ username, password }),
            credentials: 'include' 
        })  
        .then(response => response.json())
        .then(data => {
            if (data.access) {
                setUser(data.user);
                localStorage.setItem('token', data.access);  // Save access token to localStorage
                localStorage.setItem('user', data.user.id);
                setAuth(data.access);  // Update auth state
                navigate('/dashboard');  // Redirect to dashboard
            } else {
                // Handle login error
                alert('Login failed');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    

       /* if (response.ok) {
            const data = await response.json();
            setAuth(data.token);  // Save the token in state, localStorage, etc.
            navigate('/dashboard');  // Redirect to dashboard
        } else {
            const errorData = await response.json();
            setError(errorData.detail);  // Assuming the error is returned in 'detail'
        }*/
    };

    return (
        <div className='container login-content'>
            <div className="login-container">
                <h2>Bejelentkezés</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            type="text"
                            placeholder="Felhasználónév"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <input
                            type="password"
                            placeholder="Jelszó"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <button type="submit">Belépés</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
