import React, { useEffect, useState } from "react";
import logo from "../images/pdf_logo.png"; // Import the logo image

const PdfEn = ({generatedReport}) => {
  const [report, setReport] = useState();
  const [logoBase64, setLogoBase64] = useState("");

  // Convert the imported logo to a Base64 string
  useEffect(() => {
    const convertToBase64 = async () => {
      try {
        const response = await fetch(logo); // Fetch the imported image
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => setLogoBase64(reader.result); // Store the Base64 string
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error converting logo to Base64:", error);
      }
    };

    convertToBase64();
  }, []);

  // Fetch the report data
  useEffect(() => {
    
    if(generatedReport.header.vehicle)
    setReport(generatedReport);
  }, [generatedReport]);

  function handleChange(event) {
    const value = event.target.value;
  }

  return (
    <>
      {(report && report.header) && (
        <div className="report-page">
          {/* Header Section */}
          <header className="report-header">
              {logoBase64 ? (
                <img src={logoBase64} alt="Logo" style={{  width: "100%" }} />
              ) : (
                <p>Loading logo...</p>
              )}
              <h1>TESTREPORT</h1>
              <h5>Magirus-manufacturer's test specification</h5>
              <p>Report number: {report.header.vehicle.order_number}/{new Date().getFullYear()}-{report.header.rank}</p>
            </header>
            <div className="report-info">
              <h3>1. Inspection</h3>
              <table  >
                <tbody>
               
                <tr>
                  <td >Date of inspection:</td>
                  <td >{new Date(report.header.modified_at).getFullYear()}.{(new Date(report.header.modified_at).getMonth() + 1).toString().padStart(2, '0')}.{new Date(report.header.modified_at).getDate().toString().padStart(2, '0')}</td>
                </tr>
                <tr>
                  <td >Location of inspection:</td>
                  <td >HOROSZCOOP KFT. </td>
                </tr>
                <tr>
                  <td >Inspectors:</td>
                  <td >  Dániel Bányácsky, service engineer<br/></td>
                </tr>
                 
                </tbody>
              </table>
            
            </div>
            <p> Vecsés, {new Date().getFullYear()}. {(new Date().getMonth() + 1).toString().padStart(2, '0')}. {(new Date().getDate()).toString().padStart(2, '0')}</p>
          
            <div className="new-page"></div>
            <div className="vehicle-info">
            <h3>3. General informations</h3>
    <table className="formatted-table">
        <thead>
            <tr>
                <th border="0" style={{border:"none"}}></th>
                <th></th>
                <th>Actual informations</th>
                <th>Comment</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td rowSpan={4}>3.1</td>
                <td>Owner</td>
                <td>OKF</td>
                <td rowSpan={4}></td>
            </tr>
            <tr>
                <td>Local organisation:</td>
                <td>{report.header.vehicle.organization}</td>
            </tr>
            <tr>
                <td>Address:</td>
                <td>{report.header.vehicle.county}</td>
            </tr>
            <tr>
                <td>Contact person:</td>
                <td></td>
            </tr>
            <tr>
                <td>3.2</td>
                <td>Machine type:</td>
                <td>MAGIRUS Turntable ladder</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan={3}>3.3</td>
                <td>Superstructure type:</td>
                <td>{report.header.vehicle.body_type}</td>
                <td rowSpan={3}>Superstructure</td>
            </tr>
            <tr>
                <td>Order Number:</td>
                <td>{report.header.vehicle.order_number}</td>
            </tr>
            <tr>
                <td>Year of production:</td>
                <td>{report.header.vehicle.manufacturing_year}</td>
            </tr>
            <tr>
                <td rowSpan={4}>3.4</td>
                <td>Truck type:</td>
                <td>{report.header.vehicle.chassis_type}</td>
                <td rowSpan={4}>Chassis</td>
            </tr>
            <tr>
                <td>VIN Number:</td>
                <td>{report.header.vehicle.chassis_number}</td>
            </tr>
            <tr>
                <td>Year of production:</td>
                <td>{report.header.vehicle.manufacturing_year}</td>
            </tr>
            <tr>
                <td>Licence plate number:</td>
                <td>{report.header.vehicle.license_plate}</td>
            </tr>
            <tr>
                <td>3.5</td>
                <td>ODO (total mileage)</td>
                <td>{report.header.km}</td>
                <td></td>
            </tr>
            <tr>
                <td>3.6</td>
                <td>Engine working hour</td>
                <td>{report.header.motor_hours}</td>
                <td></td>
            </tr>
            <tr>
                <td>3.7</td>
                <td>Superstructure working hour</td>
                <td>{report.header.ledder_hours}</td>
                <td></td>
            </tr>
            <tr>
                <td>3.8</td>
                <td>Inspection category</td>
                <td>4</td>
                <td></td>
            </tr>
        </tbody>
    </table>
            </div>
          
            <div className="new-page"></div>

          {/* Main Categories Section */}
          <main className="report-body">
           <table className="formatted-table">
            <thead>
              <tr>
                <th>Assembly group</th>
                <th>Test</th>
                <th>Inspected</th>
              </tr>
            </thead>
            {report.main_categories.map((mainCategory) => (
               <tbody>
                <tr><td className="category" colSpan={3}>{mainCategory.name}</td></tr>
                {mainCategory.categories.map((category) => (
                  

                    <tr key={category.id}>
                      <td>
                      {category.name}
                      </td>
                   <td>
                    {category.fields.map((field) => (
                      <div key={field.id} className="field">
                         <b>{field.input && field.text}</b> {field.label}
                       
                      </div>
                       
                    ))}
                    <div style={{height:"60px",marginTop:"20px"}}>
                    <strong >Comment:</strong><br/> {category.remarks || ""}
                    </div>
                    </td>
                      <td className="checkbox-col" >
                        <label className="checkbox-container">
                           <input type="checkbox" checked={category.is_checked} readOnly={true} />
                            <span className="checkmark"></span>
                        </label>
                      </td>
                      
                     </tr> 
                    
                    
                  
                  
                ))}
             </tbody> 
            ))}
           </table>
          </main>

          {/* Summary Section */}
          <footer className="report-summary" key={report.header.close_status} style={{marginTop:"50px",marginBottom:"50px"}}>
           
                        <label className="checkbox-container"> Turntable ladder operationally safe
                           <input type="checkbox" value="Létra biztonságosan üzemeltethető" checked={report.header.close_status=="Létra biztonságosan üzemeltethető"} readOnly />
                            <span className="checkmark"></span>
                        </label><br/>
                        <label className="checkbox-container"> Turntable ladder conditionally safe to operate
                           <input type="checkbox" value="Létra csak feltételekkel üzemeltethető" checked={report.header.close_status=="Létra csak feltételekkel üzemeltethető"} readOnly/>
                            <span className="checkmark"></span>
                        </label><br/>
                        <label className="checkbox-container">Turntable ladder not operationally safe
                           <input type="checkbox" value="A létra nem biztonságos" checked={report.header.close_status=="A létra nem biztonságos"} readOnly  />
                            <span className="checkmark"></span>
                        </label><br/>
                  <p style={{marginTop:"40px"}}>Vecsés, Horoszcoop Kft, {new Date().getFullYear()}. {(new Date().getMonth() + 1).toString().padStart(2, '0')}. {(new Date().getDate()).toString().padStart(2, '0')}</p>
                <p style={{marginTop:"30px"}}>Signature of technician:</p>
          </footer>
        </div>
      )}
    </>
  );
};

export default PdfEn;
