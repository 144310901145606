import React, { useEffect, useState } from "react";
import logo from "../images/pdf_logo.png"; // Import the logo image

const PDF = ({generatedReport}) => {
  const [report, setReport] = useState();
  const [logoBase64, setLogoBase64] = useState("");

  // Convert the imported logo to a Base64 string
  useEffect(() => {
    const convertToBase64 = async () => {
      try {
        const response = await fetch(logo); // Fetch the imported image
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => setLogoBase64(reader.result); // Store the Base64 string
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error converting logo to Base64:", error);
      }
    };

    convertToBase64();
  }, []);

  // Fetch the report data
  useEffect(() => {
    console.log(generatedReport)
    if(generatedReport.header.vehicle)
    setReport(generatedReport);
  }, [generatedReport]);

  function handleChange(event) {
    const value = event.target.value;
  }

  return (
    <>
      {(report && report.header) && (
        <div className="report-page">
          {/* Header Section */}
          <header className="report-header">
              {logoBase64 ? (
                <img src={logoBase64} alt="Logo" style={{  width: "100%" }} />
              ) : (
                <p>Loading logo...</p>
              )}
              <h1>VIZSGÁLATI JEGYZŐKÖNYV</h1>
              <h5>Járműre szerelt magasból mentő (kosaras-létrás és gémes-kosaras) berendezés időszakos fővizsgálata</h5>
              <p>Jegyzőköny azonosító: {report.header.vehicle.order_number}/{new Date().getFullYear()}-{report.header.rank}</p>
            </header>
            <div className="report-info">
              <h3>1. Vizsgálat</h3>
              <table  >
                <tbody>
                <tr>
                  <td >Vizsgálati szabványok:</td>
                  <td >
                    MSZ 9721/1:2020 Emelőgépek időszakos vizsgálata. <br/>
                    Általános élőírások. <br/>
                    47/1999 GM rendelettel kiadott Emelőgép Biztonsági Szabályzat (EBSZ) <br/>
                    135/2004 GM rendelettel kiadott Emelőgép Biztonsági Szabályzat módosítás (EBSZ) <br/>
                    {report.header.vehicle.report_type==1? "DIN14701" : "EN14043"}
                  </td>
                </tr>
                <tr>
                  <td >Vizsgálat időpontja:</td>
                  <td >{new Date(report.header.modified_at).getFullYear()}.{(new Date(report.header.modified_at).getMonth() + 1).toString().padStart(2, '0')}.{new Date(report.header.modified_at).getDate().toString().padStart(2, '0')}</td>
                </tr>
                <tr>
                  <td >Vizsgálat helyszíne:</td>
                  <td >HOROSZCOOP KFT. vecsési telephelye</td>
                </tr>
                <tr>
                  <td >Vizsgálatot végzők:</td>
                  <td >  Bányácsky Dániel, szervízmérnök<br/>Turányi Zoltán, emelőgép szakértő</td>
                </tr>
                 <tr>
                  <td >Közreműködő technikus:</td>
                  <td>{report.header.user.last_name} {report.header.user.first_name}<br/></td>
                 </tr>
                </tbody>
              </table>
              <h3>2. Vizsgált emelőgép</h3>
              <table>
                <tbody>
                <tr>
                    <td>Típus:</td>
                    <td>MAGIRUS {report.header.vehicle.body_type}</td>
                  </tr>
                  <tr>
                    <td>Üzembentartó:</td>
                    <td>{report.header.vehicle.county}, {report.header.vehicle.organization}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>Kelt: Vecsés, {new Date().getFullYear()}. {(new Date().getMonth() + 1).toString().padStart(2, '0')}. {(new Date().getDate()).toString().padStart(2, '0')}</p>
            <p style={{marginTop:"50px"}}>Készítette:</p>
                                         <span style={{marginLeft:"150px"}}>________________________________</span>

            <div className="new-page"></div>
            <div className="vehicle-info">
            <h3>3. Általános adatok</h3>
    <table className="formatted-table">
        <thead>
            <tr>
                <th border="0" style={{border:"none"}}></th>
                <th>Jellemző</th>
                <th>Adat</th>
                <th>Megj.</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td rowSpan={4}>3.1</td>
                <td>Tulajdonos adatai:</td>
                <td>OKF</td>
                <td rowSpan={4}></td>
            </tr>
            <tr>
                <td>Megnevezés</td>
                <td>{report.header.vehicle.organization}</td>
            </tr>
            <tr>
                <td>Cím:</td>
                <td>{report.header.vehicle.county}</td>
            </tr>
            <tr>
                <td>Ügyintéző</td>
                <td></td>
            </tr>
            <tr>
                <td>3.2</td>
                <td>Gépfajta, -csoport</td>
                <td>MAGIRUS {report.header.vehicle.vehicle_type}</td>
                <td></td>
            </tr>
            <tr>
                <td rowSpan={3}>3.3</td>
                <td>Típus</td>
                <td>{report.header.vehicle.body_type}</td>
                <td rowSpan={3}>felépítmény</td>
            </tr>
            <tr>
                <td>Gyári szám</td>
                <td>{report.header.vehicle.order_number}</td>
            </tr>
            <tr>
                <td>Évjárat</td>
                <td>{report.header.vehicle.manufacturing_year}</td>
            </tr>
            <tr>
                <td rowSpan={4}>3.4</td>
                <td>Járműtípus</td>
                <td>{report.header.vehicle.chassis_type}</td>
                <td rowSpan={4}>hordozó jármű</td>
            </tr>
            <tr>
                <td>Alvázszám</td>
                <td>{report.header.vehicle.chassis_number}</td>
            </tr>
            <tr>
                <td>Évjárat</td>
                <td>{report.header.vehicle.manufacturing_year}</td>
            </tr>
            <tr>
                <td>Forgalmi rendszám</td>
                <td>{report.header.vehicle.license_plate}</td>
            </tr>
            <tr>
                <td>3.5</td>
                <td>Kilométeróra állása</td>
                <td>{report.header.km}</td>
                <td></td>
            </tr>
            <tr>
                <td>3.6</td>
                <td>Összes üzemóra</td>
                <td>{report.header.motor_hours}</td>
                <td></td>
            </tr>
            <tr>
                <td>3.7</td>
                <td>Felépítmény üzemóra</td>
                <td>{report.header.ledder_hours}</td>
                <td></td>
            </tr>
            <tr>
                <td>3.8</td>
                <td>Vizsgálati csoportszám</td>
                <td>4</td>
                <td></td>
            </tr>
        </tbody>
    </table>
            </div>
          
            <div className="new-page"></div>

          {/* Main Categories Section */}
          <main className="report-body">
           <table className="formatted-table">
            <thead>
              <tr>
                <th>Részegység</th>
                <th>Ellenőrzés</th>
                <th>Vizsgálva</th>
              </tr>
            </thead>
            {report.main_categories.map((mainCategory) => (
               <tbody>
                <tr><td className="category" colSpan={3}>{mainCategory.name}</td></tr>
                {mainCategory.categories.map((category) => (
                  

                    <tr key={category.id}>
                      <td>
                      {category.name}
                      </td>
                   <td>
                    {category.fields.map((field) => (
                      <div key={field.id} className="field">
                         <b>{field.input && field.text}</b> {field.label}
                       
                      </div>
                       
                    ))}
                    <div style={{height:"60px",marginTop:"20px"}}>
                    <strong >Megjegyzés:</strong><br/> {category.remarks || ""}
                    </div>
                    </td>
                      <td className="checkbox-col" >
                        <label className="checkbox-container">
                           <input type="checkbox" checked={category.is_checked} readOnly={true} />
                            <span className="checkmark"></span>
                        </label>
                      </td>
                      
                     </tr> 
                    
                    
                  
                  
                ))}
             </tbody> 
            ))}
           </table>
          </main>

          {/* Summary Section */}
          <footer className="report-summary" key={report.header.close_status} style={{marginTop:"50px",marginBottom:"50px"}}>
           
                        <label className="checkbox-container"> Létra biztonságosan üzemeltethető
                           <input type="checkbox" value="Létra biztonságosan üzemeltethető" checked={report.header.close_status=="Létra biztonságosan üzemeltethető"} readOnly />
                            <span className="checkmark"></span>
                        </label><br/>
                        <label className="checkbox-container"> Létra csak feltételekkel üzemeltethető
                           <input type="checkbox" value="Létra csak feltételekkel üzemeltethető" checked={report.header.close_status=="Létra csak feltételekkel üzemeltethető"} readOnly/>
                            <span className="checkmark"></span>
                        </label><br/>
                        <label className="checkbox-container">Létra nem biztonságos
                           <input type="checkbox" value="A létra nem biztonságos" checked={report.header.close_status=="A létra nem biztonságos"} readOnly  />
                            <span className="checkmark"></span>
                        </label><br/>
                  <p style={{marginTop:"40px"}}>Vecsés, Horoszcoop Kft telephelye, {new Date().getFullYear()}. {(new Date().getMonth() + 1).toString().padStart(2, '0')}. {(new Date().getDate()).toString().padStart(2, '0')}</p>
                <p style={{marginTop:"30px"}}>Emelőgép szakértő aláírása:</p>
          </footer>
        </div>
      )}
    </>
  );
};

export default PDF;
